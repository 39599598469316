<template>
  <!-- <div
    class="relation-account-block"
    :class="{ 'is-empty-order': emptyOrder }"
  >
    <div
      class="relation-account-card"
      @click="handleQueryCheck"
    >
      <div class="relation-account-card__left">
        <div class="relation-account-card__header">
          <i class="suiiconfont sui_icon_caution_18px"></i>
          <h4 class="relation-account-card__title">
            {{ language.SHEIN_KEY_PWA_22643 }}
          </h4>
        </div>
        <p class="relation-account-card__content">
          {{ language.SHEIN_KEY_PWA_22644 }}
        </p>
      </div>
      <div class="relation-account-card__right">
        <i class="suiiconfont sui_icon_more_right_12px"></i>
      </div>
    </div>
  </div> -->
  <div></div>
</template>

<script>
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { defineComponent } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { queryRelationAccountSer } from './service'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'

daEventCenter.addSubscriber({ modulecode: '1-20-1' })

export default defineComponent({
  name: 'RelationAccountCard',
  props: {
    language: {
      type: Object,
      default: () => ({})
    },
    emptyOrder: {
      type: Boolean,
      default: false
    },
    abt: {
      type: String,
      default: ''
    },
    thirtyLoginAbt: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    daEventCenter.triggerNotice({
      daId: '1-20-1-49',
      extraData: {
        type: this.emptyOrder ? 'new_null' : 'new'
      }
    })
  },
  methods: {
    // 三方降级过滤
    thirtyLoginFilter (list) {
      const tmps = []
      const thirtyLoginAbt = this.thirtyLoginAbt
      list?.forEach?.((v) => {
        v.register_from = parseInt(v.register_from)
        if (v.register_from == 11 && thirtyLoginAbt.facebook == 'on') return false
        if (v.register_from == 12 && thirtyLoginAbt.google == 'on')  return false
        if (v.register_from == 13 && thirtyLoginAbt.vk == 'on') return false
        if (v.register_from == 14 ) return false
        if (v.register_from == 15 && thirtyLoginAbt.line == 'on') return false
        if (v.register_from == 16 && thirtyLoginAbt.kakao == 'on') return false
        if (v.register_from == 17 && thirtyLoginAbt.naver == 'on') return false
        
        const is_third = [11, 12, 13, 14, 15, 16, 17].includes(v.register_from)
        tmps.push({ ...v, is_third })
      })
      return tmps
    },
    async handleQueryCheck() {
      daEventCenter.triggerNotice({
        daId: '1-20-1-50',
        extraData: {
          type: this.emptyOrder ? 'new_null' : 'new'
        }
      })
      const userInfo = UserInfoManager.getSync({ key: 'UserInfo', actionType: 'orderList/relation' })
      const data = await queryRelationAccountSer(userInfo?.account_type || 7)

      const { code, msg, info } = data

      if (code == 0 && info.exists_relation_account == '1') {
        const list = this.thirtyLoginFilter(info.accounts || [])
        const actionCb = ({} = {}) => {}
        const action_val = {
          list,
          abt: this.abt,
          cb: actionCb,
          from: 'order_list'
        }
        const loginCb = () => {
          location.reload()
        }
        const vparams = {
          show: true,
          cb: loginCb,
          instance: 'relationOrder',
          action: 'relation_account',
          action_val,
          bi: 'orderlist_related_account'
        }
        SHEIN_LOGIN.show(vparams)
        return
      }
      SToast(msg || this.language.SHEIN_KEY_PWA_14899)
    },
  },
})
</script>

<style lang="less">
.relation-account-block {
  padding: 32/75rem 24/75rem;

  &.is-empty-order {
    padding: 48/75rem 0 0;
  }
}

.relation-account-card {
  display: flex;
  align-items: center;
  padding: 28/75rem 24/75rem;
  text-align: left;
  color: @sui_color_gray_dark1;
  background: #fff;

  &__left {
    flex: 1;
    overflow: hidden;
    margin-right: 16/75rem;
  }

  &__right {
    flex-shrink: 0;
    [class*='sui_icon_more_right'] {
      color: @sui_color_gray_dark3;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    line-height: 18px;
    margin-bottom: 12/75rem;
  }

  &__title {
    font-family: 'SF UI Display';
    font-weight: 700;
    font-size: 14px;
    margin-left: 8/75rem;
  }

  &__content {
    font-size: 13px;
    line-height: 16px;
  }
}
</style>
