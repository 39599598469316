<template>
  <s-dialog
    v-model:visible="show"
    :append-to-body="true"
  >
    <div v-html="jumpTxt"></div>
    <template #footer>
      <s-button-group
        :width="'100%'"
        :num="2"
        hor
      >
        <s-button-group-item
          :type="['H56PX']"
          @click="tooggle(2)"
        >
          {{ language.SHEIN_KEY_PWA_15615 }}
        </s-button-group-item>
        <s-button-group-item
          :type="['primary', 'H56PX']"
          @click="jumpSite"
        >
          {{ language.SHEIN_KEY_PWA_15614 }}
        </s-button-group-item>
      </s-button-group>
    </template>
  </s-dialog>
</template>

<script>
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButtonGroup } from '@shein-aidc/sui-button-group/mobile'
import { SButtonGroupItem } from '@shein-aidc/sui-button-group-item/mobile'
import { defineComponent } from 'vue'
import { template } from '@shein/common-function'
import jumpCountry from 'public/src/pages/common/orderLogic/jumpCountry'
const { SiteUID } = gbCommonInfo

export default defineComponent({
  components: {
    SButtonGroupItem,
    SButtonGroup,
    SDialog,
  },
  props: {
    language: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      show: false,
      jumpUrl: '',
      jumpTxt: '',
      orderJumpCountry: '',
      options: {}
    }
  },
  watch: {
    orderJumpCountry (val) {
      if (val && this.language) {
        const txt = this.language.SHEIN_KEY_PWA_18627
        this.checkSite(val).then(countryObj => {
          if (countryObj.length && countryObj[0].host) {
            this.jumpTxt = this.template(val, txt)
          } else {
            this.jumpTxt = this.template(this.language.SHEIN_KEY_PWA_18632, txt)
          }
        })
      }
    },
    show: function (val) {
      if (val) {
        if (this.type == 'list') {
          daEventCenter.triggerNotice({ daId: '1-20-1-33' })
        } else {
          daEventCenter.triggerNotice({ daId: '1-19-1-53' })
        }
      }
    }
  },
  methods: {
    template,
    tooggle(btn = 1) {
      this.show = !this.show
      let daId = '1-20-1-34'
      if (this.type != 'list') {
        daId = '1-19-1-54'
      }

      daEventCenter.triggerNotice({
        daId,
        extraData: {
          click: btn
        }
      })
    },
    async jumpSite() {
      this.tooggle(1)
      if (this.orderJumpCountry && this.jumpUrl) {
        const countryHost = await this.getCountryListInfo(this.orderJumpCountry)
        location.href = countryHost + this.jumpUrl
      }
    },
    async checkSite(country) {
      // 获取所有国家
      const location_list = await jumpCountry.getLocationList()
      // 筛选出国家
      const countryObj =
        location_list?.filter(item => {
          if (item.country == country) return item
        }) || []
      return countryObj
    },
    async getCountryListInfo(country) {
      let host = ''
      if (country) {
        // 筛选出国家
        const countryObj = await this.checkSite(country)
        if (countryObj.length) {
          // 假如国家有站点+域名就跳转到这个站点域名下面
          const siteUid = countryObj[0].siteUid
          host = countryObj[0].host
          // 假如这个国家没有自己的站点就跳转到www站
          host = host || (gbCommonInfo.IS_RW ? 'm.romwe.com' : 'm.shein.com')
          // 假如没有传国家,不是从多主体提示弹窗过来的,且没有单独站点就不跳转其他站，有单独站就跳转单独站
          if (SiteUID == siteUid) {
            // 当前站点等于表里面的站点就不需要做新站点跳转
            host = ''
          }
        } else {
          // 兜底跳转国际站
          host = gbCommonInfo.IS_RW ? 'm.romwe.com' : 'm.shein.com'
        }
      }

      if (host) {
        return `//${host}`
      } else {
        return ''
      }
    }
  },
})
</script>
