<template>
  <div class="order-list-card">
    <div
      v-if="showTrackCard"
      v-tap="{
        id: '1-20-1-63',
        data: logisticsTrackAnalysis
      }"
      class="order-list-track"
      @click.stop="handelTrackClick($event)"
    >
      <div class="order-list-track__left">
        <i
          class="suiiconfont"
          :class="mallStatusData.icon"
        ></i>
      </div>
      <div class="order-list-track__content">
        <ClientOnly>
          <div class="order-list-track__content__title">
            <span class="order-list-track__content__title-status">
              {{ mallStatusData.text }}
            </span>
            <span class="order-list-track__content__title-time">
              {{ handlerDate(order.track_info.timestamp) }}
              {{ handleShortTime(order.track_info.timestamp) }}
            </span>
          </div>
        </ClientOnly>
        <div
          class="order-list-track__content__info"
          v-html="trackContent"
        >
        </div>
      </div>
      <div class="order-list-track__right">
        <i class="suiiconfont sui_icon_more_right_16px"></i>
      </div>
    </div>
    <ClientOnly>
      <div
        v-if="showTransportTime"
        class="order-list-card__transport-ime"
        :class="{ 'is-show-gutter': showTrackCard }"
        v-html="getTransportTimeDesc()"
      ></div>
    </ClientOnly>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import orderLogic from 'public/src/pages/common/orderLogic'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
import { formatTime } from 'public/src/pages/user/child_pages/orders/common/utils'
import { template } from '@shein/common-function'
import { mapState } from 'vuex'
import { ClientOnly } from '@sheinfe/vue-client-only'

let { langPath } = gbCommonInfo
export default defineComponent({
  name: 'OrderListTrack',
  directives: { tap, expose },
  props: {
    order: {
      type: Object,
      default: function () {
        return {}
      }
    },
    language: {
      type: Object,
      default: function () {
        return {}
      }
    },
    abTestConfig: {
      type: Object,
      default: function () {
        return {}
      }
    },
    showTrackCard: Boolean,
    showTransportTime: Boolean,
    isCanRefundAbt: {
      type: String,
      default: ''
    },
    orderStatusTextList: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  computed: {
    ...mapState('orderList', ['publicLocalData']),
    logisticsTrackAnalysis() {
      return {
        time: this.abTestConfig['OrderListTime'] == 'on' ? 1 : 0,
        billno: this.order.billno,
        reference_number: '-'
      }
    },
    mallStatusData() {
      const statusMap = {
        1: {
          icon: 'sui_icon_orders_circle_24px',
          text: this.language.SHEIN_KEY_PWA_16486
        },
        2: {
          icon: 'sui_icon_processing_circle_24px',
          text: this.language.SHEIN_KEY_PWA_22703
        },
        3: {
          icon: 'sui_icon_airtransport_circle_24px',
          text: this.language.SHEIN_KEY_PWA_15213
        },
        4: {
          icon: 'sui_icon_shipping_circle_24px',
          text: this.language.SHEIN_KEY_PWA_16469
        },
        5: {
          icon: 'sui_icon_tobepickedup_circle_24px',
          text: this.language.SHEIN_KEY_PWA_24252
        },
        6: {
          icon: 'sui_icon_success_24px',
          text: this.language.SHEIN_KEY_PWA_16470
        },
        0: {
          icon: 'sui_icon_processing_circle_24px',
          text: this.language.SHEIN_KEY_PWA_15215
        }
      }
      const mallStatusCode = this.trackInfo?.mall_status_code
      if (`${mallStatusCode}`) {
        if (this.cccOrderStatusText) {
          if (statusMap[mallStatusCode]?.text) {
            statusMap[mallStatusCode].text = this.cccOrderStatusText
          } else {
            statusMap[0].text = this.cccOrderStatusText
          }
        }
        return statusMap[mallStatusCode] || statusMap[0]
      }
      return statusMap[0]
    },
    //轨迹内容
    trackContent() {
      const { track_info = {} } = this.order
      if (track_info.place && track_info.details) {
        return `${track_info.place}, ${track_info.details}`
      }
      return track_info.place || track_info.details || ''
    },
    //最长时效
    maxTransportTime() {
      const mallItem = this.order.mall_list?.find(item => !!item.max_transport_time_detail)
      return mallItem?.max_transport_time_detail
    },
    cccOrderStatusText() {
      let { order_detail_status, track_info = {}, orderGoodsList = [] } = this.order || {}
      const packageNo = track_info?.package_no || ''
      const packDetailStatus = orderGoodsList?.find(item => item?.reference_number == packageNo)?.pack_detail_status

      let cccText = ''

      if (!order_detail_status && order_detail_status != 0) return ''

      if (orderLogic.orderListGetCccTextCond(this.order, this.isCanRefundAbt)) {
        let matchObj = orderLogic.getOrderStatusTextByOrderList(this.orderStatusTextList, {
          ...this.order,
          order_or_package_status: packDetailStatus
        })
        cccText = matchObj?.statusText || ''
      }

      return cccText
    },
    // *是否为海路运时效
    largeSeaAndLandObj(){
      let list = []
      this.order?.orderGoodsList.forEach(item => {
        item?.goods_sn_relation_goods_list?.forEach(goods => {
          list.push(goods)
        })
      })
      const isSomeLarge = list?.some(item => {
        return item?.large_sea_and_land == 1 || item?.large_sea_and_land == 2
      }) || false
      const isAllLarge = list?.every(item => {
        return item?.large_sea_and_land == 1 || item?.large_sea_and_land == 2
      }) || false
      return {
        isSomeLarge,
        isAllLarge
      }
    },
    // * 海运还是陆运
    seaOrLandTypeText() {
      const { orderGoodsList = [] } = this.order || {}
      let list = []
      orderGoodsList.forEach(item => {
        item?.goods_sn_relation_goods_list?.forEach(goods => {
          list.push(goods)
        })
      })
      if(list?.some(item =>  item?.large_sea_and_land == 1 )){
        return this.language.SHEIN_KEY_PWA_32176
      }
      if(list?.some(item =>  item?.large_sea_and_land == 2 )){
        return this.language.SHEIN_KEY_PWA_32175
      }
      return ''

    },
  },
  components: {
    ClientOnly
  },
  methods: {
    handlerDate(date) {
      return orderLogic.orderDateFormat(date, false, this.publicLocalData)
    },
    handleShortTime(time) {
      return orderLogic.orderCompleteShortDateFormat(time, this.publicLocalData)
    },
    // *获取海陆运时效
    getTransportLargeSeaAndLandText() {
      const { mall_list = [] } = this.order
      
      let timeListItem = mall_list[0]?.large_transport_time_info
      if(!timeListItem) {
        timeListItem = mall_list[0]?.transport_time_list?.find(item => item?.type == 2) || {}
      }
      if (timeListItem.transport_time_type == 2) {
        // 如果配置的是时间范围
        const formatDate = formatTime(timeListItem.transport_time, this.publicLocalData)
        return `${this.seaOrLandTypeText}: ${template(formatDate, this.language.SHEIN_KEY_PWA_26651)}`
        
      } 
      if(timeListItem.transport_time_type == 1){
        if (timeListItem.transport_date_type == 0) {
          return `${this.seaOrLandTypeText}: ${template(timeListItem.transport_time, this.language.SHEIN_KEY_PWA_28881)}`
           
        } 
        if(timeListItem.transport_date_type == 1) {
          return `${this.seaOrLandTypeText}: ${template(timeListItem.transport_time, this.language.SHEIN_KEY_PWA_24764)}`
        }
      }
      return ''
    },
    //获取QS时效
    getQuickShipTransportTime() {
      let { mall_list = [] } = this.order || {}
      mall_list = mall_list?.filter(mallOrStore => mallOrStore.store_code != '0')
      const item = mall_list?.[0]
      let quickShipDesc = item?.quick_ship_desc || ''
      // “时效类型”=2，格式化时间戳
      if (
        item?.quick_ship_time_type == 2 &&
        item?.quick_ship_time &&
        item?.origin_desc_quick_ship_time
      ) {
        const timeRange = item.quick_ship_time.split('-').map(Number)
        const timeStr = timeRange.map(time => this.handlerDate(time)).join('-')
        quickShipDesc = item.origin_desc_quick_ship_time.replace('%s', timeStr)
      }
      return quickShipDesc
    },
    //获取时效文案
    getTransportTimeDesc() {
      const { isSomeLarge, isAllLarge } = this.largeSeaAndLandObj
      const largeSeaAndLandText = isSomeLarge ? this.getTransportLargeSeaAndLandText() : ''
      if(isAllLarge){
        return largeSeaAndLandText
      }else {
        if (this.maxTransportTime?.type == 2) {
          return `${this.getQuickShipTransportTime() || '' } ${largeSeaAndLandText}`   
        }
        if (!this.maxTransportTime.time) return `${largeSeaAndLandText}`
        if (this.maxTransportTime?.type === 1) {
          const formatDate = formatTime(this.maxTransportTime.time, this.publicLocalData)
          return `${template(formatDate, this.language.SHEIN_KEY_PWA_24304) || '' } ${largeSeaAndLandText}`     
        } else {
          return `${template(this.maxTransportTime.time, this.language.SHEIN_KEY_PWA_24303) || '' } ${largeSeaAndLandText}`    
        }
      }
    },
    handelTrackClick(event) {
      let { parentElement, tagName } = event?.target || {}
      if (tagName === 'A' || parentElement?.tagName === 'A') return
      this.$router.push(`${langPath}/user/orders/track/${this.order.billno}`)
    }
  },
})
</script>

<style lang="less" scoped>
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type,  selector-nested-pattern  */
.line-clamp-2() {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.order-list-card {
  width: 702/75rem;
  margin: 0 auto 0.32rem auto;
  padding: 20/75rem;
  font-family: 'SF UI Text';
  color: #000000;
  box-sizing: border-box;
  background: #f6f6f6;

  &__transport-ime {
    .line-clamp-2();
    font-size: 11px;
    line-height: 13px;
    color: #666666;

    &.is-show-gutter {
      margin-top: 16/75rem;
    }
  }
}

.order-list-track {
  display: flex;

  &__left {
    padding-top: 3/75rem;

    .suiiconfont {
      line-height: 1;
      font-size: 21px;
    }
  }

  &__content {
    width: 566/75rem;
    margin-left: 11/75rem;
    margin-right: 12/75rem;

    &__title {
      font-size: 0;
      font-weight: 600;

      &-status {
        display: inline-block;
        vertical-align: bottom;
        max-width: 100%;
        margin-right: 8/75rem;
        font-size: 12px;
        line-height: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &-time {
        display: inline-block;
        vertical-align: bottom;
        font-size: 10px;
        line-height: 12px;
        margin-top: 2px;
      }
    }

    &__info {
      .line-clamp-2();
      font-size: 11px;
      line-height: 13px;
      margin-top: 8/75rem;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    .suiiconfont {
      color: #959595;
    }
  }
}
</style>
