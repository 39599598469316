// import Container from './index.vue'
import { createApp } from 'vue'

export default async () => {
  const Container = await import(
    /* webpackChunkName: "cod-verify-free-popup-module" */ './index.vue'
  ).catch(() => '')
  if (!Container?.default) return
  const appDom = document.createElement('div')
  const instance = createApp(Container?.default)
  const vm = instance.mount(appDom)
  document.body.appendChild(vm.$el)
  vm.$el.setAttribute('class', 'cod-verify-free-popup-container')

  // TODO: 待删除，unmount会自动移除节点，无需手动移除
  // instance.unmount = () => {
  //   instance.unmount()
  //   document.body.removeChild(appDom.firstElementChild)
  // }

  return instance
}
