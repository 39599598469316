<template>
  <div 
    class="check-ppga-type"
    :class="{'bottom-line': showBottomLine, 'full-line': fullLine}"
    @click.stop.prevent
  >
    <ClientOnly>
      <s-radio
        v-model="paypalGaVaultType"
        :label="checkedLabel"
        :toggle="true"
        theme="icon"
        gap="0"
        inline
        class="check-ppga-radio"
      >
        <span class="desc">{{ language.SHEIN_KEY_PWA_25002 }}</span>
      </s-radio>
    </ClientOnly>
  </div>
</template>

<script>
import { ClientOnly } from '@sheinfe/vue-client-only'
import { defineComponent } from 'vue'
import { SRadio } from '@shein-aidc/sui-radio/mobile'
export default defineComponent({
  components: {
    ClientOnly,
    SRadio
  },
  emits: ['toggle'],
  props: {
    paypalGaVault: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({})
    },
    checkedLabel: {
      type: [String, Number, Boolean],
      default: ''
    },
    showBottomLine: {
      type: Boolean,
      default: false
    },
    fullLine: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {

    }
  },
  computed: {
    paypalGaVaultType: {
      get() {
        return this.paypalGaVault.type
      },
      set() {
        this.$emit('toggle')
      }
    }
  },
})
</script>

<style lang="less">
.check-ppga-type {
  padding: 12/75rem 0;
  .flexbox();
  align-items: center;
  justify-content: flex-start;
  &.bottom-line {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 120%;
      height: 1px;
      background-color: @sui_color_gray_weak1;
      // transform: scaleY(0.5);
    }
    &.full-line:after {
      width: 100%;
    }
  }
  .desc {
    .font-dpr(24px);
    line-height: 1;
  }
  .check-ppga-radio {
    .sui-radio__check {
      .margin-r(4px);
    }
  }
}
</style>
