<template>
  <s-drawer
    v-model:visible="pageStatus.showBanksDrawer"
    :direction="'btt'"
    :append-to-body="true"
  >
    <template #title>
      {{ language.SHEIN_KEY_PWA_15965 }}
    </template>

    <div
      class="order-detail-trans-contain-footer shipping-drawer-scroll-box banks-drawer-scroll-box"
    >
      <div class="mcheo-card">
        <ul v-if="bankOptions[selectedIdealCode]">
          <li
            v-for="(item, index) in bankOptions[selectedIdealCode]"
            :key="index"
            :class="{
              'select-item':
                selectBankCode[selectedIdealCode] &&
                selectBankCode[selectedIdealCode]['id'] == item.id,
              disabled: item.is_gray == 1
            }"
            @click.stop="handleSelectClick(index, item)"
          >
            {{ item.value }}
            <i
              v-show="
                selectBankCode[selectedIdealCode] &&
                  selectBankCode[selectedIdealCode]['id'] == item.id
              "
              class="iconfont icon-normal-checked"
            ></i>
          </li>
        </ul>
      </div>
    </div>
  </s-drawer>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState, mapActions } from 'vuex'
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
export default defineComponent({
  name: 'ChooceBankPanel',
  components: {
    SDrawer,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    bankOptions: [Object, Array]
  },
  computed: {
    ...mapState({
      selectBankCode: state => state.orderDetail.selectBankCode,
      selectedIdealCode: state => state.orderDetail.selectedIdealCode,
      language: state => {
        return Object.keys(state.orderDetail.language).length
          ? state.orderDetail.language
          : state.orderList
            ? state.orderList.language
            : {}
      },
      pageStatus: state => state.orderDetail.pageStatus
    })
  },
  methods: {
    ...mapActions('orderDetail', ['updateSelectBank']),
    handleSelectClick: function (index, item) {
      if (item.is_gray == 1) {
        return
      }
      this.pageStatus.showBanksDrawer = false
      this.updateSelectBank({ code: this.selectedIdealCode, item })
      //this.selectBankCode[this.selectedIdealCode] = item
    }
  },
})
</script>

<style lang="less" scoped>
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.mcheo-card {
  > ul {
    background: #fff;
    position: relative;
    .padding-l(0.4rem);
    > li {
      position: relative;
      padding: 16/75rem 0;
      .padding-r(0.32rem);
      .align-center();
      .border-dpr(border-bottom,2px,#e5e5e5);
      &:last-child {
        border: none;
      }
      &.disabled {
        color: #999;
      }
      .payment-discount-zero {
        .margin-l(6px);
      }
      .payment-discount-tip {
        color: @sui_color_highlight;
        .font-dpr(24px);
        margin-top: 0;
      }
    }
  }

  .icon-icon-test {
    color: #999;
    .font-dpr(32px);
    float: right;
    cursor: default;
    text-decoration: none;
    font-size: 24px !important; /* stylelint-disable-line declaration-no-important */
    margin-right: -1px;
  }
  .card-left {
    width: 100%;
    .flexbox();
    .align-center();
    &.flex-auto {
      width: auto;
      flex: 1;
    }
    .icon-pro-help {
      font-size: 16px;
      color: #999;
      vertical-align: middle;
    }
    .payment-src {
      .margin-r(0.2rem);
      width: 0.8rem;
      height: 0.52rem;
    }
    .payment-disabled {
      opacity: 0.3;
    }
    > span {
      display: block;
      font-size: 14px;
      > i {
        .margin-l(0.2rem);
      }
    }
    .off-tips,
    .cod-services-cost-tips {
      color: #e64545;
      .font-dpr(22px);
    }
    .cod-services-cost-tips {
      .padding-l(0.4rem);
      &.cod-tips-wid {
        width: 5.2rem;
      }
    }
    select {
      height: 0.7733333rem;
      line-height: 0.7733333rem;
      padding: 0 0.266667rem;
      min-width: 5rem;
      background: 0 0;
      .margin-l(0.266667rem);
    }
    .text-error {
      color: @sui_color_unusual;
      .font-dpr(22px);
    }
  }
  .cod-tips-wid {
    .padding-l(0.2rem);
  }
  .paytm-upi-tips {
    color: #666;
    .font-dpr(24px);
    padding-left: 1rem;
    padding-bottom: 0.12rem;
    .input-item {
      position: relative;
      padding: 0.1rem 0 0 0;
      input::-webkit-input-placeholder {
        color: #ccc;
      }
      input {
        width: 100%;
        padding: 0 10px;
        height: 36px;
        line-height: 36px;
        border: 1px solid rgba(0, 0, 0, 0.1);
      }
      [class*='iconfont'] {
        font-size: 12px;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        display: none;
      }
      .icon-warn-out {
        color: #d53333;
      }
      .icon-checked {
        color: #5ebd66;
      }
    }
    .error-tips {
      color: #ff411c;
      display: none;
    }
    &.error-input-item {
      input {
        border-color: #ff411c;
      }
      .error-tips {
        display: block;
      }
      [class*='iconfont'] {
        display: block;
      }
    }
    &.succ-input-item {
      [class*='iconfont'] {
        display: block;
      }
    }
  }
}
</style>
<style lang="less">
.payment-drawer .banks-drawer-scroll-box {
  .method-list-title {
    margin: 0.32rem;
  }
  .return-method-btn {
    padding: 0.32rem;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
    z-index: 1; /* stylelint-disable-line declaration-property-value-blacklist */
    transform: translateZ(1px);
  }
  .refund-panel.mcheo-card {
    ul {
      padding: 0;
      margin: 0 0.32rem;
      border-bottom: 1px solid #e5e5e5;
    }
  }
  .mcheo-card ul {
    .selected-ctn {
      .desc-tip {
        margin-top: 0.213rem;
        color: #767676;
        &.wallet-tip {
          color: #ff7300;
        }
        .tishi-tip,
        .desc-text {
          font-weight: initial;
        }
        .desc-text {
          word-wrap: break-word;
          .font-dpr(24px);
        }
      }
    }
    .selected-ctn {
      font-weight: 700;
    }
    .selected-item {
      font-weight: 700 !important; /* stylelint-disable-line declaration-no-important */
      position: relative;
      & when (@IS_RW) {
        color: @sui_color_main;
      }
      .sub-title {
        font-weight: normal;
        color: #666666;
      }
      .point-tip {
        font-weight: normal;
      }
      .more_reason {
        color: @sui_color_main !important; /* stylelint-disable-line declaration-no-important */
        .fr();
      }
    }
    .return-method-item {
      padding: 0.32rem 0;
      min-height: auto;
      flex-direction: column;
      .method-radio-ctn {
        display: flex;
        align-items: center;
      }
      .method-refund-radio-ctn {
        display: flex;
        align-items: flex-start;
        width: 100%;
      }
      .return-method-con {
        flex: 1;
        display: flex;
        align-items: center;
      }
      .return-method-img-box {
        display: flex;
        align-items: center;
        .margin-r(18/75rem);
        &.disabled-item {
          opacity: 0.3;
        }
        img {
          width: 90/75rem;
          height: 60/75rem;
          border: 0;
          display: inline-block;
          object-fit: cover;
        }
      }
      .visible-hidden {
        visibility: hidden;
      }
      &:last-child {
        .return-method-radio {
          margin-bottom: 0;
        }
        .return-refund-method-item {
          border: none;
          padding: 0;
        }
      }
      .disabled-item {
        .title,
        .sub-title {
          opacity: 0.3;
        }
      }
      .title {
        .font-dpr(28px);
        color: #222222;
      }
      .sub-title {
        a {
          text-decoration: none;
        }
      }
      .disabled-title {
        .font-dpr(20px);
        color: #999;
        > i {
          .margin-r(4/75rem);
        }
      }
      .return-refund-method-item {
        width: 100%;
        .item-weight-nreachable {
          color: #fe3b30;
          .font-dpr(24px);
          a {
            color: #1860a7;
            text-decoration: none;
          }
        }
      }
    }
    .reason-item {
      border-bottom: 1px solid #e5e5e5;
      .more_reason {
        .font-dpr(32px);
        color: #999999;
        .fr();
        .flip();
      }
    }
    &.refund-detail-content {
      padding: 0 0.32rem;
      li {
        color: #222;
        font-weight: normal;
        .font-dpr(28px);
        &.total {
          border-top: 1px solid #e5e5e5;
          .total-val {
            font-weight: bold;
          }
        }
      }
    }
    .point-tip {
      color: @sui_color_highlight;
      .padding-l(4px);
      font-weight: normal;
    }
  }
  .track-content {
    max-width: 646px;
    margin: 0 auto;
    background: #ffffff;
    padding: 20px 14px;
    .txt-l();
    position: relative;
    .track-ul {
      .track-item {
        padding-bottom: 40/75rem;
        position: relative;
        .track-item-c-i {
          display: table-cell;
          background: #fff;
          height: 100%;
          line-height: 100%;
          i {
            display: table-cell;
            .font-dpr(32px);
            color: #5ebd66;
            .padding-r(0.32rem);
            & when (@IS_RW) {
              color: @sui_color_lightgreen;
            }
          }
        }
        .track-item-content {
          display: table-cell;
          p {
            padding-bottom: 8/75rem;
            .font-dpr(28px);
            color: #222;
          }
          span {
            .font-dpr(24px);
            color: #666666;
          }
        }

        &:after {
          top: 0.4rem;
          height: 100%;
          position: absolute;
          content: '';
          width: 1px;
          background: #5ebd66;
          .left(0.175rem);
          & when (@IS_RW) {
            background: #77c198;
          }
        }
        &:last-child {
          &:after {
            width: 0;
          }
        }

        &.unchecked {
          color: #ccc;
          &:after {
            background: #cccccc;
          }
          i {
            color: #cccccc;
            &:before {
              background: #ffffff;
            }
          }
          .track-item-content {
            p {
              color: #999999;
            }
          }
        }
      }
    }
  }
  .btn-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 8/75rem solid #f6f6f6;
    .order-detail-btn-white {
      color: #222222;
      height: 1.28rem;
      border: none;
      .font-dpr(28px);
      background: #fff;
    }
  }
  .footer-container {
    width: 100%;
    border-top: 16/75rem solid #f6f6f6;
    .order-detail-btn-white {
      color: #222222;
      height: 1.28rem;
      border: none;
      .font-dpr(28px);
      background: #fff;
    }
  }
}
.mcheo-card {
  > ul {
    background: #fff;
    .padding-l(0.4rem);
    > li {
      padding: 0.2rem;
      .padding-r(0.4rem);
      .align-center();
      .border-dpr(border-bottom,2px,#e5e5e5);
      &.disabled {
        color: #999;
      }
    }
  }
  .card-left {
    .flexbox();
    .align-center();
    .icon-pro-help {
      font-size: 16px;
      vertical-align: middle;
    }
    > i {
      background-image: url(/pwa_dist/images/checkcard-e488f6ed3d.png);
      background-size: 2.8rem auto;
      display: inline-block;
      .margin-r(0.2rem);
    }
    .off-tips,
    .cod-services-cost-tips {
      color: #e64545;
      .font-dpr(22px);
    }
    .cod-services-cost-tips {
      .padding-l(0.4rem);
    }
  }
  .discount-percent_tips {
    font-size: 12px;
    // color: #C44A01;
    // line-height: 14px;
    margin: 12/75rem 0;
    // .margin-l(1.1rem);
  }
  .ml-34 {
    .margin-l(0.9rem);
  }
}
.payment-drawer {
  height: auto !important; /* stylelint-disable-line declaration-no-important */
  &.payment-refund-drawer {
    height: auto !important; /* stylelint-disable-line declaration-no-important */
  }
  .shipping-drawer-scroll-box {
    height: 8.92rem;
    &.refund-drawer-scroll-box {
      height: auto !important; /* stylelint-disable-line declaration-no-important */
      padding-bottom: 0 !important; /* stylelint-disable-line declaration-no-important */
    }
  }
  .mcheo-card {
    .ppgv-arrow {
      height: 32px;
      position: absolute;
      margin-top: -16px;
      font-size: 16px;
      color: #999;
      top: 0.62rem;
      .right(0.32rem);
      .padding-l(0.8rem); // 增大点击区域
    }

    ul {
      li {
        position: relative;
        min-height: 1.17333rem;
        padding-top: 0.28rem;
        padding-bottom: 0.28rem;
        .card-left-select {
          .margin-r(0.37333rem);
          .order-detail-checkbox {
            border: 1px solid #ccc !important; /* stylelint-disable-line declaration-no-important */
            &:checked {
              border: 1px solid @color_brand !important; /* stylelint-disable-line declaration-no-important */
            }
          }
        }

        .extra-tip {
          .padding-l(0.9rem);
          font-size: 12px;
          color: #666;

          a {
            text-decoration: none;
            .icon-pro {
              font-size: 16px;
              vertical-align: middle;
            }
          }
        }

        .signup-term-info {
          .padding-l(0.9rem);
        }

        .card-logo {
          .padding-l(0.9rem);
          .card-logo-item {
            width: 42/75rem;
            height: 28/75rem;
            display: inline-block;
            .margin-r(12/75rem);
            margin-bottom: 12/75rem;
            padding: 0;
            min-height: auto;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .payments-list {
      max-height: 7.2rem;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .paytm-upi-tips {
      // padding-left: 0.9rem;
      padding: 0 0 0 0.9rem;
      .input-item {
        input {
          height: 30px;
          line-height: 30px;
        }
      }
    }
  }
  .banks-drawer-scroll-box {
    padding-top: 0.2rem;
    &.return-method-ctn {
      padding-top: 0;
      .method-list-title:last-child {
        margin-bottom: 0;
        padding-bottom: 0.82rem;
        border: none;
      }
    }
    &.new-refund-method-ctn {
      height: auto;
      padding-bottom: 1.73rem;
    }
    .mcheo-card {
      ul {
        li {
          border-bottom: 0;
          &.disabled-item {
            color: #999999;
            .desc-tip {
              margin-top: 0.05rem;
              .font-dpr(24px);
              .desc-text {
                .margin-l(0.1rem);
              }
            }
          }
        }
        .select-item {
          font-weight: bold;
        }
        .icon-normal-checked {
          color: @color_brand;
          .font-dpr(32px);
          .fr();
        }
      }
    }
  }
  &.order-payment-list {
    .shipping-drawer-scroll-box {
      height: auto;
    }
    .sui-drawer__normal-footer {
      padding: 0.4rem;
      height: auto;
      line-height: initial;
    }
  }
  .payment-bank {
    .fr();
    color: #999;
    white-space: nowrap;
  }
  .shipping-drawer-header .drawer-header-title {
    position: relative;
    &.reason-header-title {
      text-align: center;
      width: 100%;
    }
  }
  .shipping-drawer-tip {
    padding: 0.2rem 0.4rem;
    .font-dpr(24px);
    background: #f6f6f6;
    .tishi-tip {
      .margin-r(0.1rem);
    }
  }
}
</style>
