<template>
  <div
    class="orderList-con-box list-con-box"
  >
    <ul
      v-for="(mallOrStore, mallIndex) in goodsStoreOrMallList"
      :key="mallIndex"
      :class="['list-con', mallIndex < goodsStoreOrMallList.length - 1 ? 'list-con__pre' : '']"
    >
      <!-- mall名称 -->
      <div 
        v-if="mallOrStore.mall_name"
        class="mall-name-wapper"
      >
        <div
          class="mall-name"
          v-html="mallOrStore.mall_name"
        >
        </div>
        <sui_icon_doubt_12px_2
          v-if="!!mallOrStore.mall_name_tip"
          v-expose="{
            id: '1-20-1-128',
            data: {
              order_no:order.billno
            },
          }"
          class="mall-icon-margin"
          color="#959595"
          size="12px"
          @click.stop="showMallNameTipEvt(mallOrStore)"
        />
      </div>
      <li
        v-for="(store, storeIndex) in getOrderGoodsListByStore(mallOrStore.orderGoodsListByStore)"
        :key="storeIndex"  
        :class="[
          'list-con-li',
          storeIndex == getOrderGoodsListByStore(mallOrStore.orderGoodsListByStore).length - 1 ? 'list-con-li__last' : ''
        ]"
      >
        <!-- store名称 -->
        <div
          v-if="store.store_name"
          class="store-name"
          @click.stop="jumpToStoreIndex(store)"
        >
          <sui_icon_me_shop_15px
            v-if="!!store.store_icon"
            size="15px"
            class="logo-default"
          />
          <span
            class="store-text"
            v-html="store.store_name"
          >
          </span>
          <i
            v-if="store.storeRouting && store.store_code != 1"
            class="iconfont icon-m_forward_competition_40px store-icon"
          ></i>
        </div>
        <div
          v-for="(product, pIndex) in store.goodList"
          :key="pIndex"
          class="list-con-img"
        >
          <CropImageContainer
            :img-src="transformImg({ img: product.goods_img })"
            fixed-ratio="3-4"
            :transformImgProps="{ isFirstPage: index < 3 }"
          />
          <div
            v-if="product.sold_out == 1"
            @click.stop="jumpToDetail(soldoutArchor = 1)"
          >
            <div class="mask_ctn"></div>
            <div class="soldOut-box">
              <i :class="['iconfont', 'icon-soldout-64px']"></i>
              <div class="text">
                {{ language.SHEIN_KEY_PWA_16284 }}
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <span
      v-if="overTwoStoreOrMallMap"
      v-expose="{ id: '1-20-1-48', data: {} }"
      class="view-more-item"
      @click.stop="jumpToDetail"
    >
      <span class="text">{{ language.SHEIN_KEY_PWA_18635 }}</span>
      <i class="iconfont icon-m_forward_competition_40px icon"></i>
    </span>
    <ClientOnly>
      <SLazyMount>
        <cccMallNameNoticeDialog 
          :language="language"
          :visible="showMallNameTip"
          :content="showMallNameText"
          @closeMallNmaeDialog="showMallNameTip = false"
        />
      </SLazyMount>
    </ClientOnly>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import schttp from 'public/src/services/schttp'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { transformImg } from '@shein/common-function'
import { expose } from 'public/src/pages/common/analysis/directive.js'
import cccMallNameNoticeDialog from 'public/src/pages/user/child_pages/orders/common/cccMallNameNoticeDialog.vue'
import { CropImageContainer } from 'public/src/pages/components/CropImageContainer/index.js'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { sui_icon_doubt_12px_2, sui_icon_me_shop_15px } from '@shein-aidc/icon-vue3'
import { SLazyMount } from '@shein-aidc/sui-lazy-mount/mobile'

daEventCenter.addSubscriber({ modulecode: '1-20-1' })

const { langPath } = gbCommonInfo
export default defineComponent({
  name: 'PlatFormItem',
  directives: { expose },
  components: {
    CropImageContainer,
    sui_icon_doubt_12px_2,
    cccMallNameNoticeDialog,
    ClientOnly,
    sui_icon_me_shop_15px,
    SLazyMount
  },
  props: {
    order: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      showMallNameTip: false,
      showMallNameText: ''
    }
  },
  computed: {
    newStoreOrMallList () {
      let { orderGoodsListByMall = [], orderGoodsList = [] } = this.order || {}
      if (orderGoodsListByMall?.length) return orderGoodsListByMall

      // 兜底聚合展示
      return [{
        mall_name: '',
        orderGoodsListByStore: [{
          store_name: '',
          goodList: orderGoodsList
        }]
      }]
    },
    goodsStoreOrMallList () {
      return this.newStoreOrMallList?.slice(0, 2) || []
    },
    overTwoStoreOrMallMap() {
      if (this.newStoreOrMallList?.length > 2) return true

      if (this.newStoreOrMallList?.length == 1) {
        let isOverTwoStore = this.newStoreOrMallList?.[0]?.orderGoodsListByStore?.length > 2
        return isOverTwoStore
      }

      return false
    }
  },
  methods: {
    transformImg,
    showMallNameTipEvt (mallOrStore = {}) {
      daEventCenter.triggerNotice({
        daId: '1-20-1-129',
        extraData: {
          order_no: this.order.billno,
        }
      })

      this.showMallNameText = mallOrStore.mall_name_tip
      this.showMallNameTip = true
    },
    getOrderGoodsListByStore (orderGoodsListByStore = []) {
      return orderGoodsListByStore?.slice(0, 2) || []
    },
    // @todo跟app不一致
    async getStoreSelects(params) {
      let res = await schttp({
        url: `/api/store/matchStoreJumpUrl/get?storeCode=${params}`
      })
      return res?.links || {}
    },
    async jumpToStoreIndex({ storeRouting, store_code, goodList = [] }) {
      if (!storeRouting || store_code == 1) return
      daEventCenter.triggerNotice({
        daId: '1-20-1-45',
        extraData: {
          shop_code: store_code
        }
      })
      let goods_ids = [...new Set(goodList?.map(item => item.goods_id)?.filter(item => !!item) || [])]
      let cate_ids = [...new Set(goodList?.map(item => item.cat_id)?.filter(item => !!item) || [])]
      let default_href = `&rule_poskey=AfBuyShopItemList&refer_scene=order&refer_trigger_point=order`
      let href_tail = goods_ids.length ? `${default_href}&goods_ids=${goods_ids.join(',')}&main_goods_id=${goods_ids.join(',')}` : (cate_ids.length ? `${default_href}&cate_ids=${cate_ids.join(',')}&main_cate_id=${cate_ids.join(',')}` : '')
      let storeInfo = await this.getStoreSelects(store_code)
      if (storeInfo?.[store_code]) {
        if (href_tail) {
          location.href = `${langPath}${storeInfo?.[store_code]}${href_tail}`
        } else {
          location.href = `${langPath}${storeInfo?.[store_code]}`
        }
      }
    },
    jumpToDetail(soldoutArchor = 0) {
      daEventCenter.triggerNotice({
        daId: '1-20-1-47'
      })
      if (soldoutArchor) {
        // 用于锚定到售罄商品
        location.href = `${langPath}/user/orders/detail/${this.order.billno}?soldoutArchor=1`
      } else {
        location.href = `${langPath}/user/orders/detail/${this.order.billno}`
      }
    }
  },
})
</script>
<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.list-con {
  padding: 0.3rem;
  .border-dpr(border-bottom,2px,#e5e5e5);
  background: #fff;
  > li {
    .flexbox();
    flex-flow: row wrap;
    padding: 24/75rem 0;
    &:last-child {
      padding: 24/75rem 0 0 0;
    }
    &:first-child {
      padding: 0;
    }
    > .list-href {
      .flexbox();
      text-decoration: none;
      position: relative;
    }
    .left-inner {
      width: 2rem;
      height: 2.64rem;
      .margin-r(0.2rem);
      position: relative;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
      .mask_ctn {
        position: absolute;
        top: 0;
        .left(0);
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
      }
      .soldOut-box {
        width: 100%;
        text-align: center;
        position: absolute;
        top: 50%;
        .left(0);
        transform: translate(0, -50%);
        color: #fff !important; /* stylelint-disable-line declaration-no-important */
        i {
          .font-dpr(60px);
          line-height: normal;
          display: inline-block;
        }
        .text {
          line-height: normal;
          text-transform: capitalize;
          .font-dpr(24px);
        }
      }
    }
    .inner {
      width: 7rem;
      .operator_box {
        margin-top: 0.213rem;
        .exchange_tips {
          .font-dpr(24px);
          color: rgba(
            255,
            65,
            28,
            1
          ) !important; /* stylelint-disable-line declaration-no-important */
        }
        .exchange_btn {
          margin-top: 0.1rem;
          a {
            display: inline-block;
            .font-dpr(24px);
            padding: 0.1rem 0.2rem;
            border: 1px solid rgba(229, 229, 229, 1);
            text-decoration: none;
            color: #222222 !important; /* stylelint-disable-line declaration-no-important */
            & when (@IS_RW) {
              height: 0.7rem;
              line-height: 0.7rem;
              border-radius: 0.35rem;
              padding: 0 0.2rem;
            }
            // & + a {
            //   .margin-l(0.2rem);
            // }
          }
        }
      }
      > p {
        line-height: 1.5;
        .font-dpr(24px);
      }
      .size {
        margin-top: 0.213rem;
        color: #666;
        > em {
          display: block;
        }
      }
      .price {
        color: #222;
        > a {
          color: #222;
          display: block;
        }
      }
      .tip-spec-content {
        .mshe-no-border {
          width: 4rem;
          font-weight: 400;
        }
      }
      .tip-spec-wrap {
        display: inline-block;
        position: relative;
        font-weight: 400;
        p {
          padding: 0.1rem;
          color: #999;
          border: 1px solid #e5e5e5;
          position: absolute;
          bottom: 0.6rem;
          .right(0);
          background: #fff;
          width: 6rem;
          z-index: 1; /* stylelint-disable-line declaration-property-value-blacklist */
          .font-dpr(24px);
        }
        [class*='iconfont'] {
          color: #999999;
        }
        &.detail {
          p {
            position: absolute;
            top: 50%;
            bottom: auto;
            .right(0.64rem);
            transform: translateY(-50%);
            padding: 0.213rem 0.426rem;
            color: #666;
            .font-dpr(28px);
            width: 8.48rem;
            box-shadow: 0 2px 16px 4px rgba(0, 0, 0, 0.16);
            border-radius: 1px;
            &:after {
              content: '';
              position: absolute;
              top: 50%;
              .right(-0.32rem);
              transform: translateY(-50%);
              width: 0;
              height: 0;
              border: 0.16rem solid transparent;
              border-left: 0.16rem solid #fff;
            }
          }
        }
      }
      .struct {
        align-items: flex-start;
        .struct-goods-name {
          .font-dpr(24px);
          color: #666666;
          font-weight: 400;
          .margin-r(0.11rem);
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .struct-price {
          .txt-r();
          span {
            .font-dpr(24px);
            color: #222;
            font-weight: bold;
            &.struct-price__dis {
              color: @sui_color_discount;
              & when (@IS_RW) {
                color: @color_brand;
              }
            }
            &.struct-price-prime {
              color: #c96e3f;
            }
          }
          del {
            .font-dpr(20px);
            color: @sui_color_gray_light1;
            font-weight: 400;
          }
        }
      }
      .size-info {
        align-items: flex-start;
        margin-top: 10/75rem;
        .size-inf {
          width: 80%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
      .goods-qs-tag {
        margin-top: 8/75rem;
        display: inline-block;
        color: #198055;
        .font-dpr(20px);
        background: #f5fcfb;
        padding: 3/75rem 8/75rem;
      }
    }
    .exchange-goods {
      .size {
        opacity: 0.3;
        color: #666666 !important; /* stylelint-disable-line declaration-no-important */
      }
      .price {
        opacity: 0.3;
        color: #666666 !important; /* stylelint-disable-line declaration-no-important */
        > a {
          opacity: 0.3;
          color: #666666 !important; /* stylelint-disable-line declaration-no-important */
        }
      }
      .struct {
        .struct-goods-name {
          opacity: 0.3;
          color: #666666 !important; /* stylelint-disable-line declaration-no-important */
        }
        .struct-price {
          span {
            opacity: 0.3;
            color: #666666 !important; /* stylelint-disable-line declaration-no-important */
          }
          del {
            opacity: 0.3;
            color: #666666 !important; /* stylelint-disable-line declaration-no-important */
          }
        }
      }
      .tip-spec-wrap {
        p {
          opacity: 0.3;
          color: #666666 !important; /* stylelint-disable-line declaration-no-important */
        }
      }
    }
  }
  .mall-name-wapper {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 16/75rem;
  } 
  .mall-icon-margin {
    margin-left: 8/75rem;
  }
}
.list-con-box.orderList-con-box {
  .list-con {
    padding: 0.32rem 20/75rem !important; /* stylelint-disable-line declaration-no-important */
  }
  .list-con__pre {
    padding: 0.32rem 0.32rem 0 0.32rem !important; /* stylelint-disable-line declaration-no-important */
  }
  .list-con-li {
    padding: 0 0 20/75rem 0 !important; /* stylelint-disable-line declaration-no-important */
    &.list-con-li__last {
      padding: 0 !important; /* stylelint-disable-line declaration-no-important */
    }
  }
  .mall-name {
    text-align: left;
    .text-overflow();
    font-weight: 700;
    .font-dpr(28px);
    text-transform: capitalize;
    white-space: pre;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #222;
  }
  .store-name {
    display: flex;
    text-align: left;
    .text-overflow();
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-bottom: 20/75rem;
    .store-logo {
      width: 24/75rem;
      height: 24/75rem;
      display: inline-block;
      .margin-r(8/75rem);
      border-radius: 50%;
    }
    .logo-default {
      font-weight: initial;
      display: inline-block;
      .margin-r(8/75rem);
      .font-dpr(28px);
    }
    .store-text {
      .font-dpr(24px);
      display: inline-block;
      text-transform: capitalize;
      white-space: pre;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .store-icon {
      .font-dpr(24px);
      .padding-l(8/75rem);
      color: #222;
      display: inline-block;
    }
  }
  .view-more-item {
    .font-dpr(24px);
    padding: 0 0 0.32rem 0;
    margin: 0 0.32rem;
    text-align: center;
    text-transform: capitalize;
    display: block;
    display: flex;
    align-items: center;
    color: #222;
    justify-content: center;
    .icon {
      .font-dpr(24px);
    }
  }
  .list-con-img {
    position: relative;
  }
  .mask_ctn {
    position: absolute;
    top: 0;
    .left(0);
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }
  .soldOut-box {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    .left(0);
    transform: translate(0, -50%);
    color: #fff !important; /* stylelint-disable-line declaration-no-important */
    i {
      .font-dpr(60px);
      line-height: normal;
      display: inline-block;
    }
    .text {
      line-height: normal;
      text-transform: capitalize;
      .font-dpr(24px);
    }
  }
}
</style>
