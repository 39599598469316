<template>
  <div
    v-if="reverseTrackInfo.title"
    v-expose="{ id: '1-20-1-95', data: { return_front_type: reverseTrackInfo.returnFrontType } }"
    v-tap="{ id: '1-20-1-96', data: { order_no: order.billno, return_front_type: reverseTrackInfo.returnFrontType } }"
    class="order-list_warp"
    @click.stop="jumpToDetail"
  >
    <div class="order-list_application">
      <div class="application-status_icon">
        <img
          :src="reverseTrackInfo.logo"
          :class="{ 'error_img': reverseTrackInfo.showType == 'exceptionStyle' }"
          alt=""
        />
      </div>
      <div class="application-status_ctn">
        <div
          v-expose="reverseTrackInfo.returnFrontType == 8 ? { id: '1-20-1-133' } : {}"
          v-tap="reverseTrackInfo.returnFrontType == 8 ? { id: '1-20-1-132', data: { show_type: 2 } } : {}"
          :class="['status-ctn_title', { 'error-ctn_title': reverseTrackInfo.showType == 'exceptionStyle' }]"
        >
          {{ reverseTrackInfo.title }}
        </div>
        <div
          v-if="!!reverseTrackInfo.desc"
          ref="statusCtnDesc"
          class="status-ctn_desc"
        >
          <div
            ref="statusCtnInfo"
            class="status-ctn_info"
          >
            {{ reverseTrackInfo.desc }}
            <span
              v-if="showLess"
              class="status-ctn_less"
              @click.stop="showMoreEvt"
            >
              {{ language.SHEIN_KEY_PWA_28641 }}
            </span>
          </div>
          <div
            v-if="showMore"
            class="status-ctn_more"
            @click.stop="hideMoreEvt"
          >
            ...{{ language.SHEIN_KEY_PWA_23768 }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="reverseTrackInfo.refundTimeLimit"
      class="status-ctn_agingText"
    >
      {{ reverseTrackInfo.refundTimeLimit }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
daEventCenter.addSubscriber({ modulecode: '1-20-1' })

export default defineComponent({
  directives: {
    tap,
    expose
  },
})
</script>
<script setup>
import { defineProps, computed, ref, nextTick, onMounted } from 'vue'
import { useStore } from 'public/src/pages/user/child_pages/orders/list/hooks/index.js'

const store = useStore()
const langPath = computed(() => {
  if (typeof window !== 'undefined') {
    return gbCommonInfo.langPath
  }
  return store?.state?.orderList?.publicLocalData?.langPath || ''
})
let props = defineProps({
  language: {
    type: Object,
    default: () => {}
  },
  order: {
    type: Object,
    default: () => {}
  }
})

let showMore = ref(false)
let showLess = ref(false)
let statusCtnDesc = ref(null)
let statusCtnInfo = ref(null)

let reverseTrackInfo = computed(() => {
  return props.order?.reverse_track_info || {}
})

let jumpToDetail = () => {
  let { billno } = props.order || {}
  let { return_order_no, refund_bill_id } = reverseTrackInfo.value || {}
  let linkMap = {
    1: `${langPath.value}/orders/returnLabelNew/${billno}?return_order_id=${return_order_no}`,
    2: `${langPath.value}/orders/returnLabelNew/${billno}?return_order_id=${return_order_no}`,
    3: `${langPath.value}/orders/returnLabelNew/${billno}?return_order_id=${return_order_no}`,
    4: `${langPath.value}/orders/returnLabelNew/${billno}?return_order_id=${return_order_no}`,
    5: `${langPath.value}/orders/refundLabel/${billno}?refund_bill_id=${refund_bill_id}`,
    6: `${langPath.value}/orders/refundLabel/${billno}?refund_bill_id=${refund_bill_id}`,
    7: `${langPath.value}/orders/refundLabel/${billno}?refund_bill_id=${refund_bill_id}`,
    8: `${langPath.value}/orders/returnPackageList?billno=${billno}`
  }
  let jumpLink = linkMap[reverseTrackInfo?.value?.returnFrontType] || ''

  if (!jumpLink) return
  location.href = jumpLink
}

let hideMoreEvt = () => {
  statusCtnDesc.value.style.maxHeight = 'inherit'
  showMore.value = false
  showLess.value = true
}

let showMoreEvt = () => {
  statusCtnDesc.value.style.maxHeight = '34px'
  showMore.value = true
  showLess.value = false
}

onMounted(async () => {
  await nextTick()
  showMore.value = statusCtnDesc?.value?.clientHeight < statusCtnInfo?.value?.clientHeight
  showLess.value = false
})
</script>

<style lang="less" scoped>
.order-list_warp{
  margin: 0 .32rem 20/75rem .32rem;
  padding: 20/75rem 20/75rem 16/75rem 20/75rem;
  background-color: rgba(248, 248, 248, 0.75);
}
.order-list_application{
  display: flex;
}
.application-status_icon{
  .margin-r(8/75rem);
  img {
    width: 40/75rem;
    height: 42.5/75rem;
    display: inline-block;
    border: none;
    outline: none;
    object-fit: contain;
    &.error_img{
      width: 40/75rem;
      height: 40/75rem;
    }
  }
}
.application-status_ctn{
  flex: 1;
  .txt-l();
  .status-ctn_title{
    .font-dpr(24px);
    font-weight: 600;
    color: var(--sui-color-gray-dark-1, #000);
    word-break: break-word;
    &.error-ctn_title{
      color: #BF4123;
    }
  }
  .status-ctn_desc{
    margin-top: 8/75rem;
    .font-dpr(24px);
    font-weight: 400;
    color: var(--sui-color-gray-dark-1, #000);
    word-break: break-word;
    max-height: 34px;
    overflow: hidden;
    position: relative;
  }
  .status-ctn_info{
    line-height: 17px;
  }
  .status-ctn_more{
    position: absolute;
    bottom: 0;
    .right(0);
    padding: 0 8/75rem;
    line-height: 17px;
    background: #F6F6F6;
    color: var(--sui-color-link, #2D68A8);
    text-transform: capitalize;
  }
  .status-ctn_less{
    padding: 0 8/75rem;
    line-height: 17px;
    background: #F6F6F6;
    color: var(--sui-color-link, #2D68A8);
    text-transform: capitalize;
  }
}
.status-ctn_agingText{
  margin-top: 16/75rem;
  .font-dpr(24px);
  font-weight: 400;
  color: var(--sui-color-gray-light-1, #959595);
  word-break: break-word;
}
</style>
