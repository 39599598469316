import { SToast } from '@shein-aidc/sui-toast/mobile'
import schttp from 'public/src/services/schttp'
function orderFactory() {
  return {
    createOrderToCart: function (context, page) {
      let _this = context
      let _page = page || ''
      const addSourceMap = {
        'order_list': 18,
        'order_detail': 19,
      }
      const addSource = addSourceMap[_page] || ''
      return (billno, cb, from, params) =>{
        if (_page && _this?.abtInfos?.abTestConfig?.['Repurchase'] === 'on') {
          SToast(_this.language.SHEIN_KEY_PWA_17115)
          return
        }
        const self = _this
        _this.changeRootStatus({
          loading: true
        })
        schttp({
          url: `/api/orders/base/orderToCart/update?billno=${billno}&addSource=${addSource}`,
          schttp: { needLogin: true }
        }).then(async json => {
          await window._gb_app_?.$store?.dispatch('fetchCartInfo', { root: true })
          self.changeRootStatus({
            loading: false
          })
          if (json.code == 0) {
            let successTips = params ? params.successTips : true
            if (from != 'cancel' && successTips) {
              SToast(_this.language.SHEIN_KEY_PWA_15347)
            }
          } else if (json.code == 300373) {
            SToast(_this.language.SHEIN_KEY_PWA_15551)
          } else {
            SToast(json.tips || _this.language.SHEIN_KEY_PWA_15409)
          }
          cb && cb(json)
        })
      }
    },
    createOrderReduction: function (context, cb) {
      let _this = context
      return (billno) =>{
        const language = _this.language
        _this.changeRootStatus({
          loading: true
        })
        schttp({
          url: '/api/orders/base/recycleOrder/delete?recycleType=1&billno=' + billno,
          schttp: { needLogin: true }
        }).then(data => {
          _this.changeRootStatus({
            loading: false
          })
          if(data.code == 0){
            daEventCenter.triggerNotice({
              daId: '1-33-1-2',
              extraData: {
                result: 0,
              }
            })
            cb && cb(data)
          } else{
            daEventCenter.triggerNotice({
              daId: '1-33-1-2',
              extraData: {
                result: 1,
              }
            })
            if (data.code == 666666 || data.code == 777777) {
              SToast(language.SHEIN_KEY_PWA_17115)
            } else {
              SToast(data.tips || language.SHEIN_KEY_PWA_15976)
            }
          }
        })
      }
    }
  }
}

export default orderFactory()
