<template>
  <div>
    <div
      @click="order.isReadOnly != 1 && order.return_status != 3 ? jumpReturnRefundList(order) : ''"
    >
      <div :class="['list-title', { 'fsp-element': index < 3 }]">
        <em v-if="order.return_status != 'null'">
          <i :class="'order-status-' + returnStatusClass[order.return_status]"></i>
          <i class="status_text">{{ returnStatusText[order.return_status] }}</i>
        </em>
        <em
          v-if="order.union_return_tags"
          class="combine_return_icon"
          @click.stop="showCombineReturnTips"
        >
          {{ language.SHEIN_KEY_PWA_23067 }}
          <i class="suiiconfont sui_icon_doubt_16px_2"></i>
        </em>
      </div>
      <div :class="['list-title', 'list-item-info', { 'fsp-element': index < 3 }]">
        <span>{{ language.SHEIN_KEY_PWA_16822 }} {{ order.billno }}</span>
      </div>
      <ReturnNotice
        :language="language"
        :order="order"
      />
      <!-- 巴西平台化迁移 -->
      <platFormItem
        :class="{ 'fsp-element': index < 3 }"
        :order="order"
        :isOpenShopUrl="abtInfos.isOpenShopUrl"
        :isNewStoreIcon="abtInfos.isNewStoreIcon"
        :language="language"
      />
    </div>
    <orderListApplication
      v-if="order.returnStatusInfo"
      :order="order"
      :language="language"
      :wait-send-return-order="waitSendReturnOrder"
    />
    <div :class="['text-capitalize', 'list-bot-price-item-total', { 'fsp-element': index < 3 }]">
      <span class="list-bot-price-item">
        {{ order.orderGoodsSum }}
        <template v-if="order.orderGoodsSum == 1">
          {{ language.SHEIN_KEY_PWA_14952 }}
        </template>
        <template v-else>
          {{ language.SHEIN_KEY_PWA_15456 }}
        </template>
      </span>
      <span
        v-if="order.refund_currency"
        class="list-bot-price-total"
      >
        {{ language.SHEIN_KEY_PWA_15231 }}:
        <span class="price">
          {{ order.refund_currency.amountWithSymbol }}
        </span>
      </span>
    </div>
    <div class="return-btns">
      <ClientOnly>
        <div class="btn-wrapper">
          <template v-for="btn in (order.operation || [])">
            <div
              v-if="btn.show"
              :key="btn.Key"
              class="return-btn"
            >
              <div>
                <button
                  v-expose="{ id: btn.exposeId || ''}"
                  class="order-return-item-btn-black order-return-item-btn-s"
                  @click="btn.click"
                >
                  {{ btn.name }}
                </button>
              </div>
            </div>
          </template>
        </div>
        <div
          v-if="order.moreOperation && order.moreOperation.length > 0"
          class="my-reviewed-comment"
          @click="showMoreBtnClick(order)"
        >
          <div
            v-show="order.showMoreBtn"
            class="btn-more-list"
          >
            <p
              v-for="(item, childIndex) in order.moreOperation"
              :key="childIndex"
              v-expose="{ id: item.exposeId || ''}"
              class="btn-more"
              @click="item.click"
              v-html="item.name"
            ></p>
          </div>
          <a>
            <span 
              v-if="abtInfos.moreStyle === 'text' && publicLocalData.lang != 'euqs'"
              class="more-text"
            >
              {{ language.SHEIN_KEY_PWA_23768 }}
            </span>
            <template v-else>
              <span class="point"></span>
              <span class="point"></span>
              <span class="point"></span>
            </template>
          </a>
        </div>
      </ClientOnly>
    </div>
  </div>
</template>

<script>
import { expose } from 'public/src/pages/common/analysis/directive'
import { defineComponent } from 'vue'
// 订单列表-退货分组
import platFormItem from './platFormItem.vue'
import orderListApplication from './orderListApplication.vue'
import { mapState, mapGetters } from 'vuex'
import { htmlDecode } from '@shein/common-function'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import ReturnNotice from './ReturnNotice.vue'

daEventCenter.addSubscriber({ modulecode: '1-20-1' })
export default defineComponent({
  name: 'OrderReturnItem',
  directives: {
    expose,
  },
  emits: ['urge-return-evt', 'show-combined-return'],
  components: {
    platFormItem,
    orderListApplication,
    ReturnNotice,
    ClientOnly
  },
  props: {
    order: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    },
    jumpReturnRefundList: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      returnStatusClass: {
        0: 'blue',
        1: 'blue',
        2: 'green',
        3: 'grey',
        4: 'green'
      }
    }
  },
  computed: {
    ...mapState('orderList', ['language', 'abtInfos', 'extraInfo', 'publicLocalData', 'abnormalOrderList']),
    ...mapGetters('orderList', ['returnStatusText']),
    waitSendReturnOrder () {
      return this.extraInfo?.waitSendReturnOrder || []
    }
  },
  mounted() {
    this.getOperation()
  },
  methods: {
    htmlDecode,
    showCombineReturnTips() {
      this.$emit('show-combined-return', true)
    },
    showMoreBtnClick(order) {
      order.showMoreBtn = !order.showMoreBtn
    },
    getOperation(){
      if(typeof window == 'undefined') return
      // 看看屏幕可以展示几个按钮 
      const windowWidh = window.screen?.width > 540 ? 540 : window.screen?.width
      const canShow = Math.floor(windowWidh / 230)
      const btns = this.returnBtns(this.order)
      this.order.operation = btns.splice(0, canShow)
      this.order.moreOperation = btns
      this.order.showMoreBtn = false
    },
    showModifyReturn(order = {}) {
      if (!this.abtInfos?.showModifyReturnApp) return false
      const { modifiableList = [] } = order?.returnInfo || {}
      if (modifiableList?.some(i => i.order_goods_can_modify == 1)) {
        return true
      }
      return false
    },
    isShowModifyOrder(order) {
      // abt 是开的
      if(typeof window == 'undefined') return
      const { HAPPY_RETURN_CHANNEL, RETURN_TRACK_SWITCH } = gbCommonInfo
      
      if (
        RETURN_TRACK_SWITCH === 'on' &&
        HAPPY_RETURN_CHANNEL?.includes(order?.shipping_type)
      ) {
        return false
      }
      if (this.showModifyReturn(order)) return true
      if(!this.abtInfos.isShowReturnAndRefund) return false
      let isShow = false
      const { return_order_list,  refund_order_list } = order?.returnInfo || {}
      const returnOrderStatus = return_order_list?.filter(i => {
        return i.return_order_status == 0 && i.shipping_status != 13 && i.return_order_no?.length == 10
      }) || []

      isShow = returnOrderStatus.length > 0
      if(returnOrderStatus.length > 0 && refund_order_list?.length > 0) {
        for(let i = 0; i < returnOrderStatus.length; i++) {
          const cur = returnOrderStatus[i]
          const handle = refund_order_list.find(i => i.refund_bill_id == cur.refund_bill_id)
          if(!handle) {
            isShow = true
            break
          }
          if(handle.refund_status != 0) {
            isShow = false
          }
        }
      }
    
      return isShow
    },
    isShowCancelOrder(order) {
      if(!this.abtInfos.isShowReturnAndRefund) return false
      let isShow = false
      const { return_order_list,  refund_order_list } = order?.returnInfo || {}
      const returnOrderStatus = return_order_list?.filter(i => {
        return i.return_order_status == 0 && i.shipping_status != 4 && i.return_order_no?.length == 10
      } ) || []
   
      isShow = returnOrderStatus.length > 0
      if(returnOrderStatus.length > 0 && refund_order_list?.length > 0) {
        for(let i = 0; i < returnOrderStatus.length; i++) {
          const cur = returnOrderStatus[i]
          const handle = refund_order_list.find(i => i.refund_bill_id == cur.refund_bill_id)
          if(!handle) {
            isShow = true
            break
          }
          if(handle.refund_status != 0) {
            isShow = false
          }
        }
      }

      return isShow
    },
    modifyHandle(order){
      daEventCenter.triggerNotice({
        daId: '1-20-1-158'
      })
      this.jumpReturnRefundList(order)
    },
    cancelHandle(order){
      daEventCenter.triggerNotice({
        daId: '1-20-1-160'
      })
      this.jumpReturnRefundList(order)
    },
    returnBtns(order){
      const btns = [
        {
          key: 'modifyOrder',
          name: this.showModifyReturn(order) ? this.language.SHEIN_KEY_PWA_35584 : this.language.SHEIN_KEY_PWA_18019,
          show: this.isShowModifyOrder(order),
          click: () => {
            this.modifyHandle(order)
          },
          exposeId: '1-20-1-159',
          priority: 2
        },
        {
          key: 'Return&RefundRecord',
          name: this.language.SHEIN_KEY_PWA_17013,
          show: order.return_status != 3,
          click: () => {
            this.jumpReturnRefundList(order)
          },
          exposeId: '',
          priority: 1
        },
        {
          key: 'cancelOrder',
          name: this.language.SHEIN_KEY_PWA_17020,
          show: this.isShowCancelOrder(order),
          click: () => {
            this.cancelHandle(order)
          },
          exposeId: '1-20-1-161',
          priority: 2
        },
        {
          key: 'urgeReturn',
          name: this.language.SHEIN_KEY_PWA_31578,
          show: order.return_order_list?.length,
          click: () => {
            this.$emit('urge-return-evt', order)
          },
          exposeId: '',
          priority: 2
        }
      ]
      return btns.filter(i => i.show).sort((a, b) => a.priority - b.priority)
    }
  },
})
</script>
<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.order-return-item-btn-black {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  box-sizing: border-box;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214s
  background: #FFF;
  .border-dpr(border, 2px, transparent);
  border-color: #222222;
  padding: 0 0.4rem;
  color: #000;
  white-space: nowrap;
  user-select: none;
  text-decoration: none;
  text-transform: uppercase;
  transition: background 0.2s, border-color, 0.2s, color 0.2s;
  height: 1.16rem;
  line-height: 1.16rem;
  .font-dpr(32px);

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    background: fade(@color_brand, 60%);
    color: rgba(255, 255, 255, 0.8);
    cursor: not-allowed;
  }

  a& {
    text-decoration: none;
    &.disabled,
    fieldset[disabled] & {
      pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
    }
  }
}
.order-return-item-btn-s {
  height: 0.8rem;
  line-height: 0.8rem;
  .font-dpr(24px);
}
.macc-order-list {
  background: #fff;
  margin-top: 16/75rem;
  .free-trial-tag {
    .font-dpr(20px);
    padding: 0.02666rem 0.05333rem;
    border-radius: 1px;
    text-transform: capitalize;
    .margin-l(0.106rem);
  }
  .brithday-gift {
    .font-dpr(20px);
    .margin-l(0.1rem);
    color: #bf9f53;
    .brithday-gift_tag {
      display: inline-block !important; /* stylelint-disable-line declaration-no-important */
      width: 0.28rem;
      height: 0.32rem;
      .margin-r(0.1rem);
      border: none;
      vertical-align: initial;
    }
  }
  .free-gift_tag {
    .margin-l(8/75rem);
    color: @sui_color_promo_dark;
    background: @sui_color_promo_bg;
    .font-dpr(24px);
    font-weight: 400;
    display: inline-block;
    padding: 2/75rem 8/75rem;
  }
  .combined-order {
    color: #666;
    .padding-l(4px);
    i {
      .font-dpr(16px);
    }
  }
  &.order-list-new {
    .list-title {
      font-size: 14px;
      border: 0;
      padding: 0.32rem 0.32rem 0;
      em {
        position: relative;
        display: flex;
        align-items: center;
        .status_text {
          font-weight: bold;
          display: inline-block;
          color: #222;
          .font-dpr(28px);
          margin-right: 0;
        }
        .status-pending {
          .font-dpr(24px);
          color: @sui_color_link;
          // transform: scale(.83);
          display: inline-block;
          // .margin-l(-.3rem);
        }
        .question_icon {
          font-weight: normal;
        }
        .tips_con {
          display: inline-block;
          padding: 0.1rem;
          color: #999;
          border: 1px solid #e5e5e5;
          position: absolute;
          top: -0.1rem;
          .left(100%);
          background: #fff;
          width: 5rem;
          word-break: break-word;
          z-index: 1; /* stylelint-disable-line declaration-property-value-blacklist */
          .font-dpr(22px);
          font-weight: normal;
        }
      }
      .split-package {
        color: #666666;
        .font-dpr(24px);
      }
    }
    .list-tips {
      margin: 0.16rem 0.32rem 0 0.32rem;
      padding: 0.16rem 0;
      margin-bottom: 0.16rem;
      background-color: #fcf0d4;
      color: #222222;
      display: flex;
      align-items: center;
      .font-dpr(24px);
      span.text {
        flex: 1;
        .padding-l(0.16rem);
        &.no-btn {
          padding: 0 0.16rem;
        }
      }
      span.btn {
        padding: 0 0.16rem;
      }
    }
    .list-item-info {
      .font-dpr(24px);
      color: #666666;
      padding: 8/75rem 0 24/75rem 0;
      margin: 0 0.32rem;
      border-bottom: 1px solid #e5e5e5;
    }
    .list-bot-price-item-total {
      padding: 0.32rem;
      color: #666666;
      .font-dpr(24px);
      .txt-r();
      .list-bot-price-total {
        .price {
          font-weight: 600;
          color: #222222;
        }
      }
    }
    .list-bot-giftcard {
      display: flex;
      align-items: flex-start;
      .cancel-giftcard {
        .margin-r(24/75rem);
        font-weight: normal;
      }
    }
    .list-con {
      padding: 0.32rem 0.32rem 0 0.32rem;
      border: none;
      > li {
        display: block;
        white-space: nowrap;
        overflow-x: auto;
        scroll-behavior: smooth;
        overflow-y: hidden;
        &::-webkit-scrollbar {
          width: 0px;
          height: 0px;
        }
        .list-con-img {
          display: inline-block;
          width: 2rem;
          margin-right: 8/75rem;
          img {
            max-width: 100%;
            height: 198/75rem;
          }
        }
      }
      &.last-list-con {
        > li {
          border-bottom: none;
        }
      }
    }
    &.giftcard {
      .list-con {
        .list-con-img {
          display: flex;
          width: 100%;
          img {
            width: 100%;
            height: auto;
          }
          .inner-left {
            width: 152/75rem;
            height: 94/75rem;
            overflow: hidden;
          }
          .inner-right {
            .margin-l(16/75rem);
            .font-dpr(24px);
            color: #666;
            .price {
              margin-top: 16/75rem;
            }
          }
        }
      }
      .list-bot-price-item-total {
        padding: 0.32rem;
      }
      .order-list-countdown .pay-down-tip-arrow {
        position: relative;
        display: block;
        width: 0.3rem;
        height: 0.3rem;
        border-right: 0.3rem solid transparent;
        border-left: 0.3rem solid transparent;
        border-top: none;
        margin: 0 auto;
        .zindex-translatez(8,translate3d(0,0,8px));
        border-bottom: 0.3rem solid @sui_color_welfare;
      }
    }
  }
  .list-title {
    padding: 0.2rem 0.4rem;
    .border-dpr(border-bottom,2px,#e5e5e5);
    > span {
      user-select: text;
      .font-dpr(24px);
    }
    > em {
      display: block;
      > i {
        width: auto;
        height: auto;
        .padding-l(8/75rem);
        display: inline-block;
        border-radius: 100%;
        &.order-status-processing,
        &.order-status-returned,
        &.order-status-verified,
        &.order-status-refunded,
        &.order-status-shipped,
        &.order-status-paid,
        &.order-status-green {
          background: @sui_color_safety;
        }
        &.order-status-canceled,
        &.order-status-expired,
        &.order-status-revoked,
        &.order-status-red {
          background: @sui_color_unusual;
          // background: #FAAD14;
          // & when (@IS_RW) {
          // 	background: #FACB5A;
          // }
        }
        &.order-status-waitting,
        &.order-status-verifty,
        &.order-status-blue {
          background: @sui_color_link;
        }
        &.order-status-delivered,
        &.order-status-grey {
          background: @sui_color_gray_light2;
        }
        &:first-child {
          vertical-align: middle;
          padding: 0.08rem;
        }
      }
      &.combine_return_icon {
        display: inline-flex;
        align-items: center;
        color: #666;
        .font-dpr(24px);
        i {
          .font-dpr(24px);
        }
      }
    }
  }
  .list-con-more {
    text-align: center;
    padding: 0.2rem 0.4rem;
    .border-dpr(border-bottom,2px,#e5e5e5);
    > i {
      .margin-l(0.2rem);
      display: inline-block;
      &.up {
        transform: rotateX(180deg);
        transition: all 0.3s ease;
      }
    }
  }
  .my-reviewed-comment {
    position: relative;
    top: 0;
    .left(0);
    a {
      display: inline-flex;
      align-items: center;
      height: 0.8rem;
      text-decoration: none;
      .font-dpr(20px);
      column-gap: 8/75rem;
      .more-text {
        color: @sui_color_link;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
      }
      .point {
        width: 0.1rem;
        height: 0.1rem;
        background: @sui_color_link;
        border-radius: 0.07rem;
        border: 2px solid @sui_color_link;
        display: inline-block;
      }
    }
    .btn-more-list {
      position: absolute;
      background: #fff;
      padding: 0.1rem 0.3rem;
      color: #666;
      text-transform: capitalize;
      top: 0.8rem;
      .left(-0.12rem);
      white-space: nowrap;
      z-index: 10; /* stylelint-disable-line declaration-property-value-blacklist */
      .font-dpr(24px);
      border: 1px solid #e5e5e5;
      box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
      .txt-l();
      &::after {
        content: ' ';
        display: block;
        width: 0.3rem;
        height: 0.3rem;
        border: 1px solid #e5e5e5;
        position: absolute;
        .left(0.25rem);
        top: -0.15rem;
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
        background: #fff;
        border-bottom: #fff;
        border-left: #fff;
      }
      .btn-more {
        padding: .06rem 0;
        &.opacity-item {
          background: #fff;
          color: #ccc;
          opacity: 0.7;
          filter: alpha(opacity=70);
        }
      }
    }
  }
  .return-btn {
    padding-left: .32rem;
  }
  .return-btns {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 0 .32rem .32rem .32rem;
  }
  .btn-wrapper {
    display: flex;
  }
  .btn-wrapper div:last-child div{
    .order-return-item-btn-black {
      background: @color_brand;
      color: #fff;
    }
  }
}
</style>
